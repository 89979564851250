import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Button,
  Dropdown
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardDoctorPendingTaskUX = (props: any) => {
    return(
      <div
        id="CardDoctorPendingTaskUX-div-0"
        style={{ width: "100%" , pointerEvents: props.loading? "none" : ""}}>
        {}
        <div
          className="ui segment"
          id="CardDoctorPendingTaskUX-div-1"
          style={{backgroundColor: "#f2f2f2", boxShadow: "none", border: "none"}}>

          <div
            className="ui form"
            id="CardDoctorPendingTaskUX-div-2"
            style={{marginBottom: "-1rem"}}>

            <FormGroup
              id="CardDoctorPendingTaskUX-FormGroup-3"
              inline={true}>
              <FormField
                id="CardDoctorPendingTaskUX-FormField-4"
                inline={true}
                width={4}>
                <label
                  id="CardDoctorPendingTaskUX-label-9">
                  HN
                </label>
                <div
                  id="CardDoctorPendingTaskUX-div-15"
                  style={{width: "100%"}}>
                  {props.SearchBox}
                </div>
              </FormField>
              <FormField
                id="CardDoctorPendingTaskUX-FormField-5"
                inline={true}
                width={4}>
                <label
                  id="CardDoctorPendingTaskUX-label-10">
                  สถานะ
                </label>
                <Dropdown
                  fluid={true}
                  id="CardDoctorPendingTaskUX-Dropdown-14"
                  name="status"
                  onChange={props.onChangeValue}
                  options={props.doctorPendingTaskOptions}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.status}>
                </Dropdown>
              </FormField>
              <FormField
                id="CardDoctorPendingTaskUX-FormField-6"
                inline={true}
                width={3}>
                <label
                  id="CardDoctorPendingTaskUX-label-11"
                  style={{width: "50px"}}>
                  วันที่
                </label>
                <div
                  id="CardDoctorPendingTaskUX-div-16"
                  style={{width: "100%"}}>
                  {props.StartDate}
                </div>
              </FormField>
              <FormField
                id="CardDoctorPendingTaskUX-FormField-7"
                inline={true}
                width={3}>
                <label
                  id="CardDoctorPendingTaskUX-label-12">
                  ถึง
                </label>
                <div
                  id="CardDoctorPendingTaskUX-div-17"
                  style={{width: "100%"}}>
                  {props.EndDate}
                </div>
              </FormField>
              <FormField
                id="CardDoctorPendingTaskUX-FormField-8"
                inline={true}
                width={2}>
                <Button
                  color="blue"
                  fluid={true}
                  id="CardDoctorPendingTaskUX-Button-13"
                  loading={props.loading}
                  onClick={props.onSearch}>
                  ค้นหา
                </Button>
              </FormField>
            </FormGroup>
          </div>
        </div>
        <Table
          data={props.items}
          getTdProps={props.getTdProps}
          getTrProps={props.getTrProps}
          headers="ผู้ป่วย / ผู้ปฏิบัติงาน / เวลา, 	รายละเอียด, Approve,Comment"
          id="CardDoctorPendingTaskUX-Table-18"
          keys="patient_name, detail, menu, comment"
          showPagination={false}
          style={{height: "calc(100dvh - 19rem)"}}>
        </Table>
        <div
          className="ui form"
          id="CardDoctorPendingTaskUX-div-19"
          style={{display: "flex", justifyContent: "flex-end", margin: "1rem"}}>

          <Button
            color={"green"}
            id="CardDoctorPendingTaskUX-Button-20"
            onClick={props.onSave}>
            Save
          </Button>
        </div>
      </div>
    )
}


export default CardDoctorPendingTaskUX

export const screenPropsDefault = {}

/* Date Time : Thu Mar 13 2025 15:07:59 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardDoctorPendingTaskUX-div-0"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" , pointerEvents: props.loading? \"none\" : \"\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui segment"
        },
        "id": {
          "type": "value",
          "value": "CardDoctorPendingTaskUX-div-1"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#f2f2f2\", boxShadow: \"none\", border: \"none\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "id": {
          "type": "value",
          "value": "CardDoctorPendingTaskUX-div-2"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"-1rem\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDoctorPendingTaskUX-FormGroup-3"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormField",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDoctorPendingTaskUX-FormField-4"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormField",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDoctorPendingTaskUX-FormField-5"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormField",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDoctorPendingTaskUX-FormField-6"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDoctorPendingTaskUX-FormField-7"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDoctorPendingTaskUX-FormField-8"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "label",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "HN"
        },
        "id": {
          "type": "value",
          "value": "CardDoctorPendingTaskUX-label-9"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "label",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะ"
        },
        "id": {
          "type": "value",
          "value": "CardDoctorPendingTaskUX-label-10"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "label",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่"
        },
        "id": {
          "type": "value",
          "value": "CardDoctorPendingTaskUX-label-11"
        },
        "style": {
          "type": "code",
          "value": "{width: \"50px\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "label",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "id": {
          "type": "value",
          "value": "CardDoctorPendingTaskUX-label-12"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Button",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardDoctorPendingTaskUX-Button-13"
        },
        "loading": {
          "type": "code",
          "value": "props.loading"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearch"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Dropdown",
      "parent": 5,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardDoctorPendingTaskUX-Dropdown-14"
        },
        "name": {
          "type": "value",
          "value": "status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.doctorPendingTaskOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.status"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SearchBox"
        },
        "id": {
          "type": "value",
          "value": "CardDoctorPendingTaskUX-div-15"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "code",
          "value": "props.StartDate"
        },
        "id": {
          "type": "value",
          "value": "CardDoctorPendingTaskUX-div-16"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.EndDate"
        },
        "id": {
          "type": "value",
          "value": "CardDoctorPendingTaskUX-div-17"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 18,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.items"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.getTdProps"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "value",
          "value": "ผู้ป่วย / ผู้ปฏิบัติงาน / เวลา, \tรายละเอียด, Approve,Comment"
        },
        "id": {
          "type": "value",
          "value": "CardDoctorPendingTaskUX-Table-18"
        },
        "keys": {
          "type": "value",
          "value": "patient_name, detail, menu, comment"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"calc(100dvh - 19rem)\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "id": {
          "type": "value",
          "value": "CardDoctorPendingTaskUX-div-19"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", margin: \"1rem\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Button",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "Save"
        },
        "color": {
          "type": "code",
          "value": "\"green\""
        },
        "id": {
          "type": "value",
          "value": "CardDoctorPendingTaskUX-Button-20"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSave"
        }
      },
      "seq": 20,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardDoctorPendingTaskUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
