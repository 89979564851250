import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Checkbox,
  Label,
  Button,
  TextArea,
  Input
} from 'semantic-ui-react'

const CardDoctorNoteOrderUX = (props: any) => {
    return(
      <div>
        <div
          className="ui form"
          id="CardDoctorNoteOrderUX-div-50"
          style={{padding: "2rem 2rem"}}>
          
          <FormGroup
            id="CardDoctorNoteOrderUX-FormGroup-51"
            inline={true}
            style={{ ...( props.hideContinuousCommand && { display: "none" } ) }}>
            <FormField
              id="CardDoctorNoteOrderUX-FormField-56"
              inline={true}>
              <Checkbox
                checked={props.DoctorNoteOrderSequence?.chkCont?.checked}
                disabled={!props.DoctorNoteOrderSequence?.chkCont?.enabled}
                id="CardDoctorNoteOrderUX-Checkbox-66"
                label="คำสั่งต่อเนื่อง"
                onChange={props.onChangeChkCont}
                style={{minWidth: "max-content"}}
                toggle={true}>
              </Checkbox>
            </FormField>
            <FormField
              id="CardDoctorNoteOrderUX-FormField-57"
              inline={true}
              style={{flex: 1}}>
              <Label
                id="CardDoctorNoteOrderUX-Label-67"
                size="large"
                style={{width: "100%"}}>
                {props.DoctorNoteOrderSequence?.plan_item?.date_time_summary || "\u00a0"}
              </Label>
            </FormField>
            <FormField
              id="CardDoctorNoteOrderUX-FormField-58"
              inline={true}>
              <Button
                color="yellow"
                disabled={!props.DoctorNoteOrderSequence?.chkCont?.checked || !props.DoctorNoteOrderSequence?.chkCont?.enabled}
                fluid={true}
                id="CardDoctorNoteOrderUX-Button-68"
                onClick={props.onOpenModPlanning}
                size="small">
                แก้ไขแผน
              </Button>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardDoctorNoteOrderUX-FormGroup-52"
            inline={true}
            style={{ display: props.drNoteOrderPermission?.config_core_ENABLE_OUT_PERFORM_DIV ? "" : "none" }}>
            <FormField
              id="CardDoctorNoteOrderUX-FormField-59"
              inline={true}>
              <Checkbox
                checked={props.DoctorNoteOrderSequence?.is_out_perform_div || false}
                id="CardDoctorNoteOrderUX-Checkbox-69"
                label="คำสั่งนอกแผนก"
                name="is_out_perform_div"
                onChange={props.onChangeData}
                style={{minWidth: "max-content"}}>
              </Checkbox>
            </FormField>
            <FormField
              id="CardDoctorNoteOrderUX-FormField-61"
              inline={true}
              width={16}>
              <div
                id="CardDoctorNoteOrderUX-div-71"
                style={{width: "100%"}}>
                {props.divisionSearchBox}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardDoctorNoteOrderUX-FormGroup-53"
            inline={true}
            style={{marginBottom: "0.5rem"}}>
            <FormField
              id="CardDoctorNoteOrderUX-FormField-62"
              inline={true}>
              <label
                id="CardDoctorNoteOrderUX-label-72"
                style={{fontWeight: "normal"}}>
                คำสั่ง
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardDoctorNoteOrderUX-FormGroup-54"
            inline={true}>
            <FormField
              id="CardDoctorNoteOrderUX-FormField-63"
              inline={true}
              width={16}>
              <TextArea
                disabled={props.DoctorNoteOrderSequence?.readOnly}
                id="CardDoctorNoteOrder-TextArea-Detail"
                name="detail"
                onChange={props.onChangeData}
                rows={4}
                value={props.DoctorNoteOrderSequence?.detail || ""}>
              </TextArea>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardDoctorNoteOrderUX-FormGroup-55"
            inline={true}
            style={{display:props.hideButtonSave?"none":""}}>
            <FormField
              id="CardDoctorNoteOrderUX-FormField-76"
              inline={true}
              style={{width: "100%", maxWidth: "max-content"}}>
              <label
                id="CardDoctorNoteOrderUX-label-77"
                style={{display: props.roleTypes?.includes("DOCTOR") ? "none" : ""}}>
                เหตุผลที่ดำเนินการแทนแพทย์
              </label>
            </FormField>
            <FormField
              id="CardDoctorNoteOrderUX-FormField-64"
              inline={true}
              width={16}>
              <Input
                fluid={true}
                id="CardDoctorNoteOrder-Input-Note"
                name="note"
                onChange={props.onChangeData}
                style={{ display: props.roleTypes?.includes("DOCTOR") ? "none" : "" }}
                value={props.DoctorNoteOrderSequence?.note}>
              </Input>
            </FormField>
            <FormField
              id="CardDoctorNoteOrderUX-FormField-65"
              inline={true}>
              <div
                id="CardDoctorNoteOrderUX-div-75">
                {props.buttonAction}
              </div>
            </FormField>
          </FormGroup>
        </div>
      </div>
    )
}


export default CardDoctorNoteOrderUX

export const screenPropsDefault = {}

/* Date Time : Tue Apr 01 2025 14:36:02 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "id": {
          "type": "value",
          "value": "CardDoctorNoteOrderUX-div-50"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"2rem 2rem\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormGroup",
      "parent": 50,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDoctorNoteOrderUX-FormGroup-51"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ ...( props.hideContinuousCommand && { display: \"none\" } ) }"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "FormGroup",
      "parent": 50,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDoctorNoteOrderUX-FormGroup-52"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.drNoteOrderPermission?.config_core_ENABLE_OUT_PERFORM_DIV ? \"\" : \"none\" }"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "FormGroup",
      "parent": 50,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDoctorNoteOrderUX-FormGroup-53"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "FormGroup",
      "parent": 50,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDoctorNoteOrderUX-FormGroup-54"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "FormGroup",
      "parent": 50,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDoctorNoteOrderUX-FormGroup-55"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display:props.hideButtonSave?\"none\":\"\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "FormField",
      "parent": 51,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDoctorNoteOrderUX-FormField-56"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "FormField",
      "parent": 51,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDoctorNoteOrderUX-FormField-57"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "FormField",
      "parent": 51,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDoctorNoteOrderUX-FormField-58"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormField",
      "parent": 52,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDoctorNoteOrderUX-FormField-59"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "FormField",
      "parent": 52,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDoctorNoteOrderUX-FormField-61"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "FormField",
      "parent": 53,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDoctorNoteOrderUX-FormField-62"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "FormField",
      "parent": 54,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDoctorNoteOrderUX-FormField-63"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "FormField",
      "parent": 55,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDoctorNoteOrderUX-FormField-64"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormField",
      "parent": 55,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDoctorNoteOrderUX-FormField-65"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "Checkbox",
      "parent": 56,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.DoctorNoteOrderSequence?.chkCont?.checked"
        },
        "disabled": {
          "type": "code",
          "value": "!props.DoctorNoteOrderSequence?.chkCont?.enabled"
        },
        "id": {
          "type": "value",
          "value": "CardDoctorNoteOrderUX-Checkbox-66"
        },
        "label": {
          "type": "value",
          "value": "คำสั่งต่อเนื่อง"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeChkCont"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "Label",
      "parent": 57,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DoctorNoteOrderSequence?.plan_item?.date_time_summary || \"\\u00a0\""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardDoctorNoteOrderUX-Label-67"
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "Button",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": "แก้ไขแผน"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "disabled": {
          "type": "code",
          "value": "!props.DoctorNoteOrderSequence?.chkCont?.checked || !props.DoctorNoteOrderSequence?.chkCont?.enabled"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardDoctorNoteOrderUX-Button-68"
        },
        "onClick": {
          "type": "code",
          "value": "props.onOpenModPlanning"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "Checkbox",
      "parent": 59,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.DoctorNoteOrderSequence?.is_out_perform_div || false"
        },
        "id": {
          "type": "value",
          "value": "CardDoctorNoteOrderUX-Checkbox-69"
        },
        "label": {
          "type": "value",
          "value": "คำสั่งนอกแผนก"
        },
        "name": {
          "type": "value",
          "value": "is_out_perform_div"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "code",
          "value": "props.divisionSearchBox"
        },
        "id": {
          "type": "value",
          "value": "CardDoctorNoteOrderUX-div-71"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "label",
      "parent": 62,
      "props": {
        "children": {
          "type": "value",
          "value": "คำสั่ง"
        },
        "id": {
          "type": "value",
          "value": "CardDoctorNoteOrderUX-label-72"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "TextArea",
      "parent": 63,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.DoctorNoteOrderSequence?.readOnly"
        },
        "id": {
          "type": "value",
          "value": "CardDoctorNoteOrder-TextArea-Detail"
        },
        "name": {
          "type": "value",
          "value": "detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "rows": {
          "type": "code",
          "value": "4"
        },
        "value": {
          "type": "code",
          "value": "props.DoctorNoteOrderSequence?.detail || \"\""
        }
      },
      "seq": 73,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "Input",
      "parent": 64,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardDoctorNoteOrder-Input-Note"
        },
        "name": {
          "type": "value",
          "value": "note"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.roleTypes?.includes(\"DOCTOR\") ? \"none\" : \"\" }"
        },
        "value": {
          "type": "code",
          "value": "props.DoctorNoteOrderSequence?.note"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "div",
      "parent": 65,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonAction"
        },
        "id": {
          "type": "value",
          "value": "CardDoctorNoteOrderUX-div-75"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "FormField",
      "parent": 55,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDoctorNoteOrderUX-FormField-76"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", maxWidth: \"max-content\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "label",
      "parent": 76,
      "props": {
        "children": {
          "type": "value",
          "value": "เหตุผลที่ดำเนินการแทนแพทย์"
        },
        "id": {
          "type": "value",
          "value": "CardDoctorNoteOrderUX-label-77"
        },
        "style": {
          "type": "code",
          "value": "{display: props.roleTypes?.includes(\"DOCTOR\") ? \"none\" : \"\"}"
        }
      },
      "seq": 77,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardDoctorNoteOrderUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
